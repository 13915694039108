<template>
  <span
    v-if="price.visible"
    :class="[
      prefixCls('pt__price-total'),
      { [prefixCls('pt__price-total_discount')]: isDiscountTotalColor }
    ]"
  >
    <!-- 文本大小自适应 -->
    <SAdapterText
      v-if="isShowVerticalLayout"
      min-size="14"
      :text="price.txt"
    />
    <span v-else>{{ price.txt }}</span>
    <span
      v-if="+checkout.crossedPrice?.amount > 0 && isShowStrikethroughPrice"
      :class="prefixCls('pt__price-del_amounttxt')"
    >
      {{ checkout.crossedPrice?.amountWithSymbol }}
    </span>
  </span>
</template>

<script setup name="PriceAmount">
import { computed } from 'vue'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'

defineProps({
  tax: {
    type: Object,
    default: () => ({})
  },
  isShowVerticalLayout: {
    type: Boolean,
    default: false
  },
  isShowStrikethroughPrice: {
    type: Boolean,
    default: false
  },
  price: {
    type: Object,
    default: () => ({})
  },
})

// -------- useMap_**** --------
const { checkout } = useMapState([
  'checkout',
])

// 展示折扣色
const isDiscountTotalColor = computed(() => {
  return !!checkout.value.saved_total_price_text
})
</script>
<style lang="less">
@import '../../../variables.less';
  .@{prefixCls}pt {
    &__total-text {
      margin-right: 8/75rem;
    }

    &__price {
      display: flex;
      align-items: center;
      flex-wrap:wrap;
      width: 100%;

      &-total {
        .font-dpr(34px);
        font-weight: bold;
        margin-right: 8/75rem;

        &_discount {
          color: @sui_color_discount;
        }
      }

      &-tax-icon {
        display: inline-flex;
        align-items: center;
      }

      &-ti_tax {
        .font-dpr(28px);
        font-weight: bold;
      }

      &-del_amounttxt {
        color: #7B7B7B;
        text-align: right;
        font-family: "SF Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: line-through;
        text-decoration-line: strikethrough;
      }

      &-ti_icon {
        margin-left: 4/75rem;
        &.sui_icon_more_up {
          transform: rotate(180deg);
        }
      }
    }

    &__price-vertical {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
