<template>
  <div
    :id="prefixCls('total-wrapper_id')"
    ref="totalWrapperRef"
    :class="[prefixCls('total-wrapper'), animationCls]"
  >
    <!-- 价格、税费相关的信息 -->
    <PriceTax
      :isShow="props.visible"
      :isShowTaxTipPopoverTip="isShowTaxTipPopoverTip"
      :tax="tax"
      :isNotShowPriceDetail="isNotShowPriceDetail"
      @click="handleClick"
    />
    <!-- totalFee相关部分 -->
    <TotalFee
      v-if="tax.visible"
      :tax="tax"
    />

    <!-- 公司税 -->
    <CompanyTax
      v-if="companyTaxInfo.visible"
      :tax="companyTaxInfo.tax"
    />

    <!-- 税费运费差价提示优先展示，10s后隐藏，展示利诱点轮播 -->
    <ScrollText
      v-if="isShowTaxTipInView"
      v-expose="{
        id: 'expose_price_description:simple',
      }"
      :class="prefixCls('total__tax-tip')"
      :speed="60"
      :text="checkout?.newCustomerTaxTip || ''"
      :delay="3"
    />
    <payableAmountIncentive
      v-else
    />
  </div>
</template>

<script setup name="TotalWrapper">
import { ref, computed, watch, nextTick } from 'vue'

import { vExpose } from 'public/src/pages/common/analysis/directive'
import PriceTax from './components/PriceTax.vue'
import CompanyTax from './components/CompanyTax.vue'
import TotalFee from './components/TotalFee.vue'
import ScrollText from './components/scrollText.vue'
import payableAmountIncentive from './components/payableAmountIncentive.vue'

import {
  prefixCls,
  getBtnContainerWidth
} from '@/public/src/pages/checkout_new/pages/footer/utils'
import {
  useMapState,
  useMapMutations
} from '@/public/src/pages/checkout_new/hooks/store'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

const totalWrapperRef = ref(null)
const animationCls = ref('')

const emit = defineEmits(['changePriceDetailVisible'])
const { isClient } = useIsClient()

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  isShowTaxTipPopoverTip: {
    type: Boolean,
    default: false
  },
  isShowTaxTipInView: {
    type: Boolean,
    default: false
  },
  // 不展示价格明细drawer
  isNotShowPriceDetail: {
    type: Boolean,
    default: false
  }
})

// -------- useMap_**** --------
const { isPagePriceTotalShowInView, abtState, checkout, abtInfo } = useMapState(
  ['isPagePriceTotalShowInView', 'abtState', 'checkout', 'abtInfo']
)

const { changeBtnContainerWidth } = useMapMutations(['changeBtnContainerWidth'])

// ---------- computed ----------
// 税费
const tax = computed(() => {
  const txt = checkout.value?.extraTaxInfo?.taxPriceAmount || ''
  const tip = checkout.value?.extraTaxInfo?.govTaxTip || ''
  return {
    visible: !!txt,
    txt,
    tip
  }
})

// 公司税
const companyTaxInfo = computed(() => {
  const { bottomPrices } = checkout.value
  const prices = bottomPrices?.filter(item => item.type === 'company_tax')
  const list = !!prices?.length ? prices : []

  return {
    visible: !!list.length,
    tax: list
  }
})

const isMoveNeedStretch = computed(() => {
  return (
    abtInfo.value?.placeorderStatusmove?.param?.placeorderStatusmoveStatus == 1
  )
})

// ------------ watch -----------
watch(
  [
    () => animationCls.value,
    () => tax.value,
    () => companyTaxInfo.value
  ],
  () => {
    if (isClient.value) {
      nextTick(() => {
        requestAnimationFrame(() => {
          setBtnContainerWidth()
        })
      })
    }
  },
  {
    deep: true
  }
)

watch(
  () => isPagePriceTotalShowInView.value,
  val => {
    if (isClient) {
      animationCls.value = val && isMoveNeedStretch.value
        ? prefixCls('total-animation__full')
        : prefixCls('total-animation__reset')
    }
  }
)

// ---------- method ----------

const handleClick = () => {
  if (props.isNotShowPriceDetail) return
  daEventCenter.triggerNotice({
    id: 'click_promotiondetails:simple'
  })
  const openCondition = abtState.value?.isShowSaveAmount
  openCondition && emit('changePriceDetailVisible', !props.visible)
}

// 动态设定btn支付按钮的宽度
const setBtnContainerWidth = () => {
  if (!isPagePriceTotalShowInView.value) {
    const { width, isContinue } = getBtnContainerWidth(
      totalWrapperRef.value.clientWidth
    )

    if (isContinue) {
      changeBtnContainerWidth(width)
    }
  }
}
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}total-wrapper {
  line-height: 1;
  opacity: 1;
  margin-right: 20/75rem;
  //transition: opacity 0.5s ease, width 0.5s ease;
  flex: 0 0 auto;
  max-width: 4.53333333rem;
  transition: opacity 0.5s ease-in-out;
  &.@{prefixCls}total-new {
    max-width: 340/75rem;
  }

  &.@{prefixCls}total-animation {
    &__full {
      opacity: 0;
    }

    &__reset {
      opacity: 1;
    }
  }

  .@{prefixCls}total {
    &__total_fee {
      margin-top: 4/75rem;
      .font-dpr(20px);
      color: #767676;
    }

    &__save-price {
      margin-top: 8/75rem;
      .font-dpr(24px);
      color: @sui_color_discount_dark;
    }
  }
}
.@{prefixCls}total__tax-tip {
  margin-top: 3/75rem;
}
</style>
