<!-- 当前文件为主入口 -->
<!-- 任何能收敛到对应业务组件的逻辑都不要写到这里来 -->
<!-- 组件命名 A、命名语义化； B、命名使用动宾短语 -->
<template>
  <div
    id="checkout-app"
    v-expose="{
      id: 'expose_scenesabt:simple',
      data: {
        scenes: +checkout.burying_point.hasEffectIgnoreAbt === 1 ? 'holidayDeliveryShow' : ''
      }
    }"
    class="checkout__container"
  >
    <!-- header -->
    <Header />

    <!-- 吸顶容器[公告，税减免公告，无地址，断网] -->
    <StickyArea
      v-if="visibleStickArea"
    />

    <!-- 地址栏 -->
    <AddressBar class="checkout__container-item" />

    <ClientOnly>
      <!-- 在地址下方，购物袋上方 -->
      <BenefitFloor
        class="checkout__container-item"
        :show-bar="isOnlyShowBenefitBar"
        :location="0"
      />
    </ClientOnly>

    <!-- 运输方式 -->
    <ShippingMethod
      v-if="layoutMode === CombinationMode.Separation"
      class="checkout__container-item"
      :mallCode="shoppingBagsState?.bagsInfo?.mall[0]?.mall_code"
    />

    <div
      v-if="layoutMode === CombinationMode.Combination"
      class="checkout__container-item"
    >
      <div
        v-for="(_, index) in shoppingBagsState?.bagsInfo?.mall"
        :key="index"
      >
        <!-- 店铺维度的购物袋和运输方式 -->
        <StoreGroupShoppingShipping
          v-if="showNewCartGroup"
          :mallIndex="index"
          :mallCode="_.mall_code"
        />
        <template v-else>
          <!-- 购物袋 -->
          <ShoppingBags :mallIndex="index" />

          <!-- 运输方式 -->
          <ShippingMethod
            :mallCode="_.mall_code"
          />
        </template>
      </div>
    </div>

    <ClientOnly>
      <!-- 在支付方式上方 -->
      <BenefitFloor
        class="checkout__container-item"
        :location="1"
      />
    </ClientOnly>

    <!-- 支付方式列表 -->
    <PaymentOptions
      v-if="!isShowBottomPaymentSelector.show"
      class="checkout__container-item"
      :c2pInfo="c2pInfo"
      @selected-payment="handleSelectedPayment"
    />

    <ClientOnly>
      <!-- 付费会员 -->
      <CheckoutPrime
        v-if="abtState.LocationPrimeSaverStatus"
        class="checkout__container-item"
      />

      <!-- 超省卡 -->
      <CheckoutSaver
        v-else
        class="checkout__container-item"
      />
    </ClientOnly>

    <!-- 随手购如果融合，则使用如下 -->
    <BuyTogether
      v-if="isNewBuyTogether"
    />
    <BuyTogetherOld
      v-else
      class="checkout__container-item"
      :location="2"
    />

    <!--虚拟资产-->
    <VirtualAssets class="checkout__container-item" />

    <ClientOnly>
      <!-- 超省卡 -->
      <CheckoutSaver
        v-if="abtState.LocationPrimeSaverStatus"
        class="checkout__container-item"
      />

      <!-- 付费会员 -->
      <CheckoutPrime
        v-else
        class="checkout__container-item"
      />
    </ClientOnly>

    <!-- 购物袋 -->
    <ShoppingBags
      v-if="layoutMode === CombinationMode.Separation"
      class="checkout__container-item"
    />

    <ClientOnly>
      <!-- 在价格明细上方 -->
      <BenefitFloor
        class="checkout__container-item"
        :location="2"
      />
    </ClientOnly>

    <!-- 价格明细 -->
    <PriceInfo
      class="checkout__container-item"
      :is-in-drawer="false"
    />

    <!-- 积分利诱点 -->
    <RewardPoints
      class="checkout__container-item"
    />

    <!--订单返券-->
    <ReturnCoupon
      class="checkout__container-item"
    />

    <!--付费会员回本信息-->
    <PrimeBenefit
      class="checkout__container-item"
    />

    <!--辅助信息-->
    <SupportInfo
      class="checkout__container-item"
      scene="Checkout"
      :assistPolicy="assistPolicy"
      :language="language"
      :paymentSafetyExperimentAbt="abtState.paymentSafetyExperimentAbt"
      :GB_cssRight="GB_cssRight"
    />

    <!--隐私权益-->
    <AgreePolicyTip
      :isShowNewTcPpTip="abtState.isShowNewTcPpTip"
      :language="language"
    />

    <!-- footer -->
    <Footer :c2pInfo="c2pInfo" />

    <ClientOnly>
      <ShipMethodPopup />

      <CheckoutDialog />

      <SwitchCardDrawer
        v-if="primeSaverState.switchCardDrawer.show"
      />

      <MyCouponList />

      <s-loading
        type="curpage"
        :mask-transparent="true"
        :show="checkoutLoading.loading"
      />

      <PaymentLoading
        v-if="abtState.isUseNewCheckoutLoading"
        :language="language"
      />

      <BackTips
        v-if="selectedPaymentInfo?.payment_code === 'cod'"
        ref="paymentBackTipsRef"
        @back-tips-mounted="handleBackTipsMounted"
      />

      <!-- c2p验证弹窗 -->
      <SDrawer
        v-model:visible="visibleC2pCheckoutDrawer"
        class="c2p-val-drawer"
        size="80%"
        append-to-body
      >
        <iframe
          ref="c2pIframeRef"
          class="c2p-frame-container"
        ></iframe>
      </SDrawer>

      <!-- c2p alert弹窗 -->
      <BSrcValidateDrawer :c2pInfo="c2pInfo" />

      <BSrcOptInputDrawer
        ref="c2pAlertRef"
        :isShowOtpInputContent="visibleC2pAlertDrawer"
        :c2pInfo="c2pInfo"
      />

      <BSrcRequestTimeOutDialog
        :is-show-dialog="isShowBSrcRequestTimeOutDialog"
        @closeDialog="setIsShowBSrcRequestTimeOutDialog"
        @handlerConfirm="handleC2pTokenCheck"
      />

      <SLoading
        :show="signOutFetchLoading"
        type="curpage"
      />

      <SwitchPaymentDialog
        :is-show-dialog="handleStartCheckoutWithCardIsTimeout"
        :c2pInfo="c2pInfo"
        @close="setHandleStartCheckoutWithCardIsTimeout"
        @switch-payment="toOrderDetail"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { stateFactory } from './store/state'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout_new/utils/EventAggregator.js'

export default defineComponent({
  asyncData({ store }) {
    // 仅用于判定曾使用过 state
    if (store.state.checkout.ssrPageType) {
      store.state.checkout = stateFactory()
    }
    window.gbCheckoutSsrData && Object.keys(window.gbCheckoutSsrData).length && (store.commit('checkout/asyncDataMutations', window.gbCheckoutSsrData))
    window.gbCheckoutSsrData = null
  },
  beforeRouteEnter(to, from, next) {
    if (typeof window === 'undefined') return next()
    next(vm => {
      vm.reportPvAnalysis(from)
    })
  },
  beforeRouteLeave(to, from, next) {
    next(vm => {
      vm.handlePageScreenInViewArea()
    })
  }
})
</script>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { onBeforeMount, onMounted, nextTick, computed } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'

import Header from './pages/header/Index.vue'
import StickyArea from './pages/sticky/Index.vue'
import ShoppingBags from './pages/shopping_bags/Index.vue'
import Footer from './pages/footer/Index.vue'
import ShippingMethod from './pages/shipping_method/Legacy.vue'
import PrimeBenefit from './pages/prime_benefit/index.vue'
import AgreePolicyTip from './pages/agree_policy/index.vue'
import SupportInfo from './pages/support_info/index.vue'
import RewardPoints from './pages/reward_points/Index.vue'
import ReturnCoupon from './pages/return_coupon/index.vue'
import VirtualAssets from './pages/virtual_assets/Index.vue'
import AddressBar from './pages/address_bar/index.vue'
import PaymentOptions from './pages/payment_options/index.vue'
import CheckoutPrime from './pages/prime_saver/PrimeIndex.vue'
import CheckoutSaver from './pages/prime_saver/SaverIndex.vue'
import PriceInfo from './pages/price/Index.vue'
import MyCouponList from '@/public/src/pages/checkout_new/components/MyCouponList.vue'
import BenefitFloor from './pages/benefit_floor/Index.vue'
import StoreGroupShoppingShipping from './pages/store_group_shop_ship/Index.vue'

import { setupHydrationVue } from 'public/src/spaHydration/index.js'
import {
  BSrcOptInputDrawer,
  BSrcValidateDrawer,
  BSrcRequestTimeOutDialog,
  SwitchPaymentDialog
} from '@shein-aidc/bs-click-to-pay-mobile'

import { AsyncComponents } from './pages/asyncComponents'

import {
  useMapState,
  useMapGetters,
  useMapMutations,
  useMapActions
} from '@/public/src/pages/checkout_new/hooks/store'

import { useRouter } from '@/public/src/pages/checkout_new/hooks/useRouter.js'
import { usePayment } from '@/public/src/pages/checkout_new/hooks/usePayment'
import { usePaymentBackTips } from '@/public/src/pages/checkout_new/hooks/usePaymentBackTips'
import { useNetworkStatus } from '@/public/src/pages/checkout_new/hooks/useNetworkStatus.js'
import usePvReport from '@/public/src/pages/checkout_new/hooks/usePvReport.js'
import useBindEvents from '@/public/src/pages/checkout_new/hooks/useBindEvents.js'
import useMixPrivacy from '@/public/src/pages/checkout_new/hooks/useMixPrivacy.js'
import useReportAnalysis from '@/public/src/pages/checkout_new/hooks/useReportAnalysis.js'
import { useJudgeNextWork } from '@/public/src/pages/checkout_new/hooks/useJudgeNextWork.js'
import useCoupon from '@/public/src/pages/checkout_new/hooks/useCoupon.js'
import { useTogether } from '@/public/src/pages/checkout_new/hooks/useTogether'
import { CombinationMode } from '@/public/src/pages/checkout_new/utils/constant.js'
import { useC2p } from '@/public/src/pages/checkout_new/hooks/useC2p'
import { useC2pAlert } from '@/public/src/pages/checkout_new/hooks/useC2pAlert'
import { useRequestTime } from '@/public/src/pages/checkout_new/hooks/useRequestTime'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { debuggerLog, isNewBuyTogether } from '@/public/src/pages/checkout_new/utils/index.js'


setupHydrationVue()

const router = useRouter()
!isNewBuyTogether && useTogether()

/**
 * @description 板块异步组件
 * @description 非首屏，not-fsp-element，条件触发的都在此引入
 * */
const {
  // 融合后
  BuyTogether,
  // 融合前
  BuyTogetherOld,
  CheckoutDialog,
  SwitchCardDrawer,
  ShipMethodPopup,
  PaymentLoading,
  BackTips,
} = AsyncComponents

// --------- useMap_**** --------
const {
  layoutMode,
  checkout,
  shoppingBagsState,
  checkoutLoading,
  abtState,
  language,
  primeSaverState,
  checkoutNotice,
  assistPolicy,
  GB_cssRight,
  showNewCartGroup,
  paymentState,
} = useMapState([
  'layoutMode',
  'checkout',
  'shoppingBagsState',
  'checkoutLoading',
  'abtState',
  'language',
  'primeSaverState',
  'checkoutNotice',
  'assistPolicy',
  'GB_cssRight',
  'showNewCartGroup',
  'paymentState',
])
const {
  isHasAddress,
  hasTaxNotice,
  isShowBottomPaymentSelector,
  selectedPaymentInfo,
  rewardFloorConfig
} = useMapGetters([
  'isHasAddress',
  'hasTaxNotice',
  'isShowBottomPaymentSelector',
  'selectedPaymentInfo',
  'rewardFloorConfig'
])
const { handleRapidInfo, updateTaxReliefInfo, checkoutComplete, assignPaymentState, changeCheckoutLoading } = useMapMutations(['handleRapidInfo', 'updateTaxReliefInfo', 'checkoutComplete', 'assignPaymentState', 'changeCheckoutLoading'])

const { exchangingSpaScene, updateCheckoutParams, updateStock } = useMapActions([
  'exchangingSpaScene',
  'updateCheckoutParams',
  'updateStock'
])

const { paymentBackTipsRef, handleBackTipsMounted } = usePaymentBackTips()

// ------------- Hooks ----------
const { handleSelectedPayment } = usePayment()
const { handleNetworkEventAdd, handleNetworkEventRemove } = useJudgeNextWork()
const { reportPvAnalysis } = usePvReport()
const { bindEvents } = useBindEvents()
const { handleMixPrivacy } = useMixPrivacy()
const {
  handleAutoUseCoupon,
  handleAutoUsePoint,
  handleAutoUseCouponResult,
  handleAutoUseWallet,
  handleSingleBag,
  handleQuickShipping,
  handleInterestsData,
  handlePageScreenInViewArea,
  handleShippingAnalysis,
  handleNotSupportCodTipsCode,
  handleShippingGroup,
  handleShippingNoticeReport
} = useReportAnalysis()
const { useGetCouponList } = useCoupon()
const { isOnline } = useNetworkStatus()

const {
  c2pIframeRef,
  visibleC2pCheckoutDrawer,
  initC2p,
  getCardTokenList,
  handleStartCheckoutWithCard,
  isShowOtpValidateDrawer,
  closeOtpValidateDrawer,
  showOtpValidateDrawer,
  initiateValidation,
  validate,
  idLookup,
  setRememberMe,
  setC2pTokenList,
  c2pTokenList,
  checkoutWithCard,
  isShowBSrcRequestTimeOutDialog,
  setIsShowBSrcRequestTimeOutDialog,
  setHandlerC2pTokenCheck,
  handleC2pTokenCheck,
  getIsHadSheinToken,
  handleNotYou,
  signOutFetchLoading,
  handleStartCheckoutWithCardIsTimeout,
  setHandleStartCheckoutWithCardIsTimeout,
  tempOrderInfo,
  setPayOrderInfo,
  toOrderDetail,
  handlerValidateCb,
  isInitC2pSuccess,
  c2pAvailableCardBrands,
  getAllCountryList,
  phoneValidateFn,
  handlePhoneEmailConfirmSubmit,
  closeBSrcPhoneEmailConfirmDialog,
  handleStartCheckoutWithNewCard,
  handleStartFrontBindingCard,
  isNoShowBSrcTokenHeader
} = useC2p()

const {
  c2pAlertRef,
  consumerPresent,
  visibleC2pAlertDrawer,
  c2pAlertConfig,
  handleStartAccessYourCards,
  closeC2pAlertDrawer,
} = useC2pAlert()

// 设置 2000ms 作为超时限制
const { startTiming, endTiming, isTimeout, duration } = useRequestTime()

// ----------- computed ---------
const visibleStickArea = computed(() => {
  return !isOnline ||
  !isHasAddress.value ||
  !!checkoutNotice.value?.content ||
  hasTaxNotice.value ||
  +checkout.value?.free_shipping_policy_banner_info?.is_display
})

const isOnlyShowBenefitBar = computed(() => rewardFloorConfig.value.rewardBarVisible && !rewardFloorConfig.value.rewardFloorVisible)

const c2pInfo = computed(() => ({
  c2pTokenList: c2pTokenList.value,
  consumerPresent: consumerPresent.value,
  visibleC2pAlertDrawer: visibleC2pAlertDrawer.value,
  handleStartAccessYourCards,
  closeC2pAlertDrawer,
  c2pAlertConfig,
  isShowOtpValidateDrawer: isShowOtpValidateDrawer.value,
  closeOtpValidateDrawer,
  showOtpValidateDrawer,
  initiateValidation,
  validate,
  countryId: checkout.value?.address?.countryId,
  idLookup,
  setRememberMe,
  setC2pTokenList,
  checkoutWithCard,
  handleStartCheckoutWithCard,
  setHandlerC2pTokenCheck,
  isShowCheckedRememberMeAbt: abtState.value.isShowCheckedRememberMeAbt,
  setIsC2pTokenInSheinTokenList: (val) => {
    assignPaymentState({
      isC2pTokenInSheinTokenList: val
    })
  },
  handleNotYou,
  setHandleStartCheckoutWithCardIsTimeout,
  tempOrderInfo: tempOrderInfo.value,
  setPayOrderInfo,
  toOrderDetail,
  handlerValidateCb,
  isCanUseC2pToken: abtState.value?.isCanUseC2pToken,
  initC2pSrcContentInfo: abtState.value?.initC2pSrcContentInfo,
  isInitC2pSuccess: isInitC2pSuccess.value,
  c2pAvailableCardBrands: c2pAvailableCardBrands.value,
  availableCardBrands: c2pAvailableCardBrands.value,
  isShowBSrcPhoneEmailConfirmDialog: paymentState.value?.isShowBSrcPhoneEmailConfirmDialog,
  c2pPhoneAndEmailInfo: paymentState.value?.c2pPhoneAndEmailInfo,
  getAllCountryList,
  phoneValidateFn,
  handlePhoneEmailConfirmSubmit,
  closeBSrcPhoneEmailConfirmDialog,
  handleStartCheckoutWithNewCard,
  c2pNewCardPayFn: paymentState.value?.c2pNewCardPayFn,
  changeCheckoutLoading,
  isNoShowBSrcTokenHeader: isNoShowBSrcTokenHeader.value
}))

// ------------- method ---------
const initData = () => {
  reportAnalysis()

  updateStock({
    outStockCarts: checkout.value?.outStockCarts,
    outStockCartsTip: checkout.value?.outStockCartsTip
  })
  handleRapidInfo()
  updateTaxReliefInfo({ checkout: checkout.value })


  // 将创建订单需要的fingerprint提前
  window._GB_DeviceFingerPrint?.callFunc(() => {})

  // 初始化一站多合规信息
  handleMixPrivacy()

  bindEvents()

  // 网络状态监听
  handleNetworkEventAdd()
  handleNetworkEventRemove()

  // 获取券列表数据
  nextTick(() => {
    useGetCouponList()
  })

  // 监听回退事件
  window.addEventListener(
    'popstate',
    () => {
      if (!router && !location.href.includes('/checkout')) {
        location.reload()
      }
    },
    false
  )

  // 取消气泡居中
  if (
    !!checkout.value?.bottomFloatingHint?.hintTip &&
    +checkout.value?.unUsedBenefitInfo?.unusedBenefit === 1 &&
    !abtState.value?.onCheckoutBubbleStrategy
  ) {
    SToast({
      htmlString: checkout.value?.bottomFloatingHint?.hintTip, // 存在富文本
      duration: 3000
    })
  }

}

// 初始时埋点上报
const reportAnalysis = () => {
  handleAutoUseCoupon()
  handleAutoUsePoint()
  handleAutoUseWallet()
  handleShippingAnalysis()
  handleSingleBag()
  handleQuickShipping()
  handleInterestsData()
  handleNotSupportCodTipsCode()
  handleShippingGroup()
  handleShippingNoticeReport()
  handleAutoUseCouponResult()
}

const getC2pInfoCb = (res) => {
  // 1代表有本地token，且ABT结果为展示；
  // 2代表无本地有远程token，且ABT结果为展示；
  // 3代表无本地无远程新卡支付，且ABT结果为展示；
  // 4代表有本地token，且ABT结果为隐藏；
  // 5代表无本地有远程token，且ABT结果为隐藏；
  // 6代表无本地无远程新卡支付，且ABT结果为隐藏
  const isHadSheinToken = getIsHadSheinToken({ checkout: checkout.value })
  const typeObj = {
    1: res?.tokenList?.length > 0 && abtState.value?.isCanUseC2pToken,
    2: res?.tokenList?.length === 0 && res?.tokenList?.consumerPresent !== '0' && abtState.value?.isCanUseC2pToken,
    3: (res?.tokenList || [])?.length === 0 && res?.tokenList?.consumerPresent === '0' && abtState.value?.isCanUseC2pToken,
    4: res?.tokenList?.length > 0 && !abtState.value?.isCanUseC2pToken,
    5: res?.tokenList?.length === 0 && res?.tokenList?.consumerPresent !== '0' && !abtState.value?.isCanUseC2pToken,
    6: (res?.tokenList || [])?.length === 0 && res?.tokenList?.consumerPresent === '0' && !abtState.value?.isCanUseC2pToken,
  }
  debuggerLog('getC2pInfoCb=====', res, abtState.value, typeObj)
  exposeScenesAbtAggregator.addEvent({
    id: 'expose_scenesabt:simple',
    data: {
      scenes: 'c2p_exp',
      type: Number(Object.keys(typeObj).find(key => typeObj[key]))
    }
  })
  if (abtState.value?.isCanUseC2pToken) {
    endTiming()
    const isHadC2pTokenList = (res?.tokenList || []).length > 0
    debuggerLog('isHadSheinToken ====', 
      isHadSheinToken, 
      isTimeout.value, 
      duration.value,
      (res?.tokenList || []),
      isHadC2pTokenList
    )
    // 请求超时弹窗提示
    if (isTimeout.value && isHadSheinToken && isHadC2pTokenList) {
      setIsShowBSrcRequestTimeOutDialog(true)
    }
    // 设置默认记住
    setRememberMe(!!abtState.value?.isShowCheckedRememberMeAbt)

    setC2pTokenList(res?.tokenList || [])
    consumerPresent.value = res?.consumerPresent == 1
    debuggerLog('consumerPresent=====', res, consumerPresent.value, isTimeout.value, duration.value)
  }
}

const initC2pInfo = () => {
  const userInfo = UserInfoManager.getSync({ key: 'UserInfo', actionType: '/checkout' })
  initC2p().then(res => {
    if (res?.status !== 'success') return
    startTiming()
    getCardTokenList({
      // email: 'c2ptest02@gmail.com'
      email: UserInfoManager.getSync({ key: 'pwa_user_email', actionType: '/checkout' }) || '',
      area_code: userInfo?.area_code || '',
      phone: userInfo?.phone || ''
    }).then(res => {
      getC2pInfoCb(res)
    })
  })
}

defineExpose({
  reportPvAnalysis,
  handlePageScreenInViewArea
})

onBeforeMount(() => {
  updateCheckoutParams({ checkout: checkout.value, isInit: true })
  checkoutComplete({ scene: 'checkoutInit', status: 'success' })

  // 销毁聚合埋点实例
  exposeScenesAbtAggregator.flush()
  exposeScenesAbtAggregator.destroy()
})

onMounted(() => {
  exchangingSpaScene()
  if (!router) reportPvAnalysis()

  // const { email } = getInitUserInfo()
  initData()

  if (abtState.value?.isCanUseC2pToken) {
    initC2pInfo()
  }

  // 依赖初始数据只需处理一次的事件
  import(
    /* webpackChunkName: "checkout-js" */ 'public/src/pages/checkout/components/checkout.js'
  )
})
</script>

<style lang="less">
.checkout__container {
  background: #f6f6f6;
  // 等同于原来 mshe-top-pad 的字体设置
  font-size: 14px;
  padding-top: 1.1733rem;
  padding-bottom: 112/75rem;

  .checkout__container-item {
    margin-bottom: 16/75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // .S-alert {
  //   padding: 0.32rem;
  // }
}

.c2p-frame-container {
  border: none;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0 auto;

  * {
    box-sizing: border-box;
  }

  .mshe-z-returnTop {
    z-index: 99;
  }
  // 输入框获取焦点时禁用点击事件,反之亦然
  &.disabled-checkout__body-click {
    pointer-events: none;
  }
}
</style>
