import {
  useMapActions,
  useMapMutations,
} from '@/public/src/pages/checkout_new/hooks/store'
import { useShippingSelect } from '@/public/src/pages/checkout_new/pages/shipping_method/hooks/useShippingSelect'

/**
 * @description 更改购物袋商品数量
 * return {updateCartsAndCheckout} 实际执行函数
 * */
export function useAllCartsAndCheckout() {
  // --------- useMap_**** --------
  const { updateShoppingBagsData } = useMapMutations(['updateShoppingBagsData'])
  const { updateCheckoutData, updateCartsInfoData } = useMapActions([
    'updateCheckoutData',
    'updateCartsInfoData',
  ])
  const { getShippingMethodsParams, resetNormalOnSuccess } =
    useShippingSelect()
  
  const updateCartsAndCheckout = async (
    _opts = {
      isFullShippingParams: false,
      checkout: {},
      carts: {},
    },
  ) => {
    {
      const opts = {
        isFullShippingParams: false,
        ..._opts,
      }
      const current_action = _opts?.checkout?.current_action ?? ''
      // 是否由编辑商品触发的接口调用（商品变更触发的需要 计价 => 查车 串联，后端部分校验逻辑放在计价）
      const isUpdateCart = ['add_cart_item', 'update_cart_item'].includes(current_action)

      let result

      if(isUpdateCart) {
        const checkoutInfo = await updateCheckoutData({
          opts: opts.isFullShippingParams
            ? Object.assign(opts.checkout || {}, getShippingMethodsParams())
            : opts.checkout,
        })
        const cartInfo = await updateCartsInfoData(opts.carts || {})
        result = [cartInfo, checkoutInfo]
      } else {
        result = await Promise.all([
          updateCartsInfoData(opts.carts || {}),
          updateCheckoutData({
            opts: opts.isFullShippingParams
              ? Object.assign(opts.checkout || {}, getShippingMethodsParams())
              : opts.checkout,
          }),
        ])
      }
      
      if (opts.isFullShippingParams && +result[1]?.code === 0) {
        resetNormalOnSuccess()
      }
      
      updateShoppingBagsData({
        cartsInfo: result[0].info,
        checkout: result[1].info,
      })

      return result
    }
  }
  
  return { updateCartsAndCheckout }
}
