<template>
  <div
    ref="containerRef"
    class="scroll-container"
  >
    <div
      ref="contentRef"
      class="content"
    >
      <img
        v-if="icon"
        :src="icon"
        class="icon"
        style="width: 12px;"
      />
      <span v-html="text"></span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: ''
  },
  speed: {
    type: Number,
    default: 50, // 像素/秒
  },
  delay: {
    type: Number,
    default: 2, // 重新滚动延迟时间（秒）
  },
  transitionDuration: {
    type: Number,
    default: 0.5 // 过渡动画时长（秒）
  }
})

const containerRef = ref(null)
const contentRef = ref(null)
let currentPhase = 0 // 0: 初始 1: 滚动中 2: 停留中 3: 返回中
let transitionListener = null
let restartTimer = ref(null)

const resetStyle = () => {
  if(!contentRef.value) return
  contentRef.value.style.transition = 'none'
  contentRef.value.style.transform = 'translateX(0)'
  contentRef.value.style.opacity = '1'
}

const startScroll = () => {
  if (!containerRef.value || !contentRef.value) return

  const containerWidth = containerRef.value.offsetWidth
  const contentWidth = contentRef.value.scrollWidth

  if (contentWidth <= containerWidth) return

  const distance = contentWidth - containerWidth
  const duration = distance / props.speed

  resetStyle()
  void contentRef.value.offsetWidth // 强制重排

  contentRef.value.style.transition = `transform ${duration}s linear`
  contentRef.value.style.transform = `translateX(-${distance}px)`
  currentPhase = 1
}

const startReturn = () => {
  contentRef.value.style.transition = `
    transform ${props.transitionDuration}s ease-in-out,
    opacity ${props.transitionDuration}s ease-in-out
  `
  contentRef.value.style.transform = 'translateX(0)'
  contentRef.value.style.opacity = '0.5'
  currentPhase = 3
}

const checkScroll = () => {
  if (restartTimer.value) {
    clearTimeout(restartTimer.value)
    restartTimer.value = null
  }
  if (transitionListener) {
    contentRef.value?.removeEventListener('transitionend', transitionListener)
  }

  resetStyle()

  transitionListener = (e) => {
    if (currentPhase === 1) { // 滚动结束
      currentPhase = 2
      restartTimer.value = setTimeout(() => {
        startReturn()
      }, props.delay * 1000)
    } else if (currentPhase === 3) { // 返回结束
      currentPhase = 0
      contentRef.value.style.opacity = '1'
      restartTimer.value = setTimeout(startScroll, 300)
    }
  }

  contentRef.value.addEventListener('transitionend', transitionListener)
  startScroll()
}

onMounted(() => {
  checkScroll()
  window.addEventListener('resize', checkScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkScroll)
  if (transitionListener) {
    contentRef.value?.removeEventListener('transitionend', transitionListener)
  }
  if (restartTimer.value) {
    clearTimeout(restartTimer.value)
  }
  resetStyle()
})
</script>

<style scoped>
.scroll-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
  font-size: 10px;
  line-height: 1;
}

.content {
  display: inline-block;
  position: relative;
  transition-property: transform, opacity;
  will-change: transform, opacity;
  line-height: 1;
  font-size: 11px;
}

.icon {
  vertical-align: middle;
  height: 1em;
  margin-right: 0.5em;
}
</style>
